; (function ($) {
    $(function () {
        var items = localStorage.ttacnCart? JSON.parse(localStorage.ttacnCart): {};
        var vHeader = new Vue({
            el: '#i-header',
            data: {
                items: items,
                count: Object.keys(items).length,
            },
            methods: {
                clearData() {
                    this.items = null;
                    this.count = 0;
                }
            }
        });
        
        var vCart = new Vue({
            el: '.mml-ttacncart',
            data: {
                items: items,
                count: Object.keys(items).length,
                options: [
                    {text: 'None', value: 'None'},
                    {text: 'Plastic Frame', value: 'Plastic Frame'},
                    {text: 'Wooden Frame', value: 'Wooden Frame'},
                    {text: 'Metal Frame', value: 'Metal Frame'},
                ]
            },
            watch: {
            },
            methods: {
                clearData () {
                    this.items = null;
                    this.count = 0;
                },
                add: function (e, el) {
                    var btn = el ? el : e.currentTarget;
                    var ds = btn.dataset;
                    var data = {
                        image: ds.image,
                        name: ds.name,
                        quantity: 15,
                        framework: 'None'
                    }
                    if (items[ds.id]) {
                        showTip('Item Already Add to Your Enquiry Cart.');
                    } else {
                        Vue.set(vCart.items, ds.id, data);
                        items[ds.id] = data;
                        vHeader.count = Object.keys(items).length;
                        vCart.count = Object.keys(items).length;
                        saveCart();
                        showTip('Item Successfully Add to Your Enquiry Cart.');
                    }
                    
                },
                remove: function (id) {
                    Vue.set(vCart.items, id, null);
                    delete items[id];
                    vHeader.count = Object.keys(items).length;
                    vCart.count = Object.keys(items).length;
                    saveCart();
                    save();
                },
                changeFramework: function ( item ) {
                    saveCart();
                    save();
                },
                changeQuantity: function (dir, item) {
                    if (dir) {
                        ++item.quantity;
                    } else {
                        --item.quantity;
                        if (item.quantity < 15) {
                            item.quantity = 15;
                        }
                    }
                    saveCart();
                    save();
                },
                limitMin: function (item) {
                    if (item.quantity < 15) {
                        item.quantity = 15;
                    }
                    saveCart();
                    save();
                },
                showTip: showTip
            }
        });

        function saveCart() {
            var LS = window.localStorage;
            var data = JSON.stringify(items);            
            LS.ttacnCart = data;
        }

        function showTip(message) {
            var tip = document.getElementById('tips-text');
            tip.innerHTML = '<p><i class="icon-check"></i>' + message + '</p>';
            var timer = setTimeout(() => {
                $(tip).parent().removeClass('mml-show');
            }, (1500));
            $(tip).parent().addClass('mml-show');
        }
    
        if(typeof(Storage) !== "undefined")
        {
            save();
        } else {
            console.log('抱歉! 不支持 web 存储。');
        }
        function save() {
            var shopData  = $('.shopData')[0];
            var item = localStorage.getItem("ttacnCart");
            var shopHtml = '';
            var tableHtml = '';
            if (item && shopData) {
                item = JSON.parse(item);
                $.each(item, function (index, value) {
                    shopHtml += `<tr>
                                    <td>${value.name}</td>
                                    <td>${value.quantity}</td>
                                    <td>${value.framework}</td>
                                </tr>
                    `;
                });     
                shopData.value = shopHtml;           
            } 
        }
        document.addEventListener('wpcf7submit', function (event) {
            if ($('.shopData').val()) {
                localStorage.removeItem('ttacnCart');
                vHeader.clearData();
                vCart.clearData();
            }
        }, false);

    });
})(jQuery);

